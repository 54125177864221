<template>
    <div id="DonateProcess" class="border-system-style">
        <!-- <NavBar></NavBar> -->
        <div id="donate-process-wrap" class="serif">
            <img :src="require('@/assets/logo/vertical-logo.png')" />
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DonateProcess',
}
</script>
<script setup>
// import { defineAsyncComponent } from 'vue'
// const NavBar = defineAsyncComponent(() => import('@/components/NavBar.vue'))
</script>
<style lang="scss" scoped>
// #donate-process-wrap {
// @media screen and (min-width: 1280px) {
//     min-height: calc(100vh - #{$navHeight});
//     padding: 80px 20%;
// }
// @media screen and (min-width: 768px) and(max-width: 1279px) {
//     min-height: calc(100vh - #{$narrowNavHeight});
//     padding: 80px 20%;
// }
// @media screen and (max-width: 767px) {
//     min-height: 100vh;
//     padding: 80px 10px;
// }
// }
</style>
